import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import {toDate,toTime} from "@core/utils/filter";

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function salesoutbounditemUseList(config) {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'is_sel', label: '是否选中', sortable: true },
    // { key: 'id', label: 'ID', sortable: true },
    { key: 'name', label: '名称', sortable: true },
    // { key: 'outbound_id', label: '出库单ID', sortable: true },
    // { key: 'outbound_no', label: '出库单编号', sortable: true },
    // { key: 'is_gift', label: '是否赠品', sortable: true },
    // { key: 'order_id', label: '销售单ID', sortable: true },
    // { key: 'orderitem_id', label: '销售单产品明细ID', sortable: true },
    // { key: 'stocklock_id', label: '', sortable: true },
    // { key: 'ourwarehouse_id', label: '我方仓库ID', sortable: true },
    // { key: 'ourwarehouse', label: '我方仓库信息', sortable: true },
    { key: 'ourwarehouse_name', label: '所在仓库', sortable: true },
    // { key: 'our_contact', label: '我方仓库联系人', sortable: true },
    // { key: 'our_mobile', label: '我方仓库联系电话', sortable: true },
    // { key: 'our_location', label: '我方仓库地址', sortable: true },
    // { key: 'otherwarehouse_id', label: '对方仓库', sortable: true },
    // { key: 'otherwarehouse_name', label: '对方仓库名称', sortable: true },
    // { key: 'other_contact', label: '对方仓库联系人', sortable: true },
    // { key: 'other_mobile', label: '对方仓库联系电话', sortable: true },
    // { key: 'other_location', label: '对方仓库地址', sortable: true },
    // { key: 'store_id', label: 'ID', sortable: true },
    // { key: 'warehouse', label: '目的仓库/店铺', sortable: true },
    // { key: 'store', label: '店铺', sortable: true },
    // { key: 'startTime', label: '出发时间', sortable: true },
    // { key: 'endTime', label: '预计到货时间', sortable: true },
    // { key: 'delivery_time', label: '出货时间', sortable: true },
    // { key: 'arrival_time', label: '预计到货时间', sortable: true },
    // { key: 'product_id', label: '商品ID', sortable: true },
    // { key: 'stock_id', label: 'ID', sortable: true },
    // { key: 'time', label: '时间', sortable: true },
    // { key: 'batch_no', label: '产品批次', sortable: true },
    // { key: 'production_date', label: '生产日期', sortable: true },
    // { key: 'expiry_date', label: '有效日期', sortable: true },
    // { key: 'category_id', label: '分类ID', sortable: true },
    // { key: 'category_name', label: '分类名称', sortable: true },
    // { key: 'brand_id', label: '品牌ID', sortable: true },
    // { key: 'brand_name', label: '品牌名称', sortable: true },
    // { key: 'warestatus', label: '库存状态 instock_status', sortable: true },
    // { key: 'num', label: '数量', sortable: true },
    { key: 'qty', label: '计划出库数量', sortable: true },
    { key: 'trueqty', label: '实际出库数量', sortable: true },
    // { key: 'is_receive', label: '对方仓库是否已接收', sortable: true },
    { key: 'receiveqty', label: '对方接收数量', sortable: true },
    // { key: 'returnqty_good', label: '对方退货数量(良品)  ', sortable: true },
    // { key: 'returnqty_bad', label: '对方退货数量(不良品)  ', sortable: true },
    // { key: 'status', label: '出库状态 sale_outbound_status', sortable: true },
    // { key: 'state', label: '状态', sortable: true },
    // { key: 'add_time', label: '添加时间', sortable: true },
    // { key: 'modify_time', label: '修改时间', sortable: true },
        { key: 'actions', label: '出库操作' },
        { key: 'receipt', label: '回执单' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('outbounditem_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    store
      .dispatch('salesoutbounditem/warehouselist', {
        search_key: searchKey.value,
        start: start.value,
        limit: limit.value,
        order_by: orderBy.value,
        id:config,
        order_desc: isSortDirDesc.value === true ? 'desc':'',
      })
      .then(response => {
        const data = response.data.data
        const list = data.ext.outbounditemlist
        listTotals.value = data.ext.totals
        console.log("data",list)
        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
