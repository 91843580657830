<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <!--            <label>显示</label>-->
            <!--            <v-select-->
            <!--                v-model="limit"-->
            <!--                :options="limitOptions"-->
            <!--                :clearable="false"-->
            <!--                class="per-page-selector d-inline-block mx-50"-->
            <!--            />-->
            <!--            <label>条</label>-->
            <!--            <div class="demo-spacing-0">-->
            <!--              <b-alert-->
            <!--                  variant="primary"-->
            <!--                  show-->
            <!--              >-->
            <!--                <div class="alert-body" >-->
            <!--                  <span><strong>勾选产品，表示此产品需要出库</strong> </span>-->
            <!--                </div>-->
            <!--              </b-alert>-->
            <!--            </div>-->

          </b-col>


          <!-- Search -->
          <!--          <b-col-->
          <!--              cols="12"-->
          <!--              md="6"-->
          <!--          >-->
          <!--            <div class="d-flex align-items-center justify-content-end">-->
          <!--              <b-form-input-->
          <!--                  v-model="searchKey"-->
          <!--                  class="d-inline-block mr-1"-->
          <!--                  placeholder="搜索关键字..."-->
          <!--              />-->
          <!--              <b-button-->
          <!--                  variant="primary"-->
          <!--                  :to="{ name: 'apps-salesoutbounditem-edit'}"-->
          <!--              >-->
          <!--                <span class="text-nowrap">添加</span>-->
          <!--              </b-button>-->
          <!--            </div>-->
          <!--          </b-col>-->
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :order-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :order-desc.sync="isSortDirDesc"
          v-model="saveSalesOutboundArray"
      >

        <!-- Columns -->
        <!--        <template #cell(id)="data">-->
        <!--          <b-form-checkbox-->
        <!--              name="user-id"-->
        <!--              v-model="checked"-->
        <!--              :value="data.item"-->
        <!--          >-->
        <!--            #{{data.item.id}}-->
        <!--          </b-form-checkbox>-->
        <!--        </template>-->

        <!--        <b-form-checkbox-->
        <!--            name="check-button"-->
        <!--            switch-->
        <!--            inline-->
        <!--            id="is_gift"-->
        <!--            v-model="data.item.is_gift"-->
        <!--            value="1"-->
        <!--            unchecked-value="0"-->
        <!--        ></b-form-checkbox>-->


        <template #cell(name)="data">
          #{{ data.item.id }}{{ data.item.name }}{{ isGift(data.item.is_gift) }}
          <feather-icon
              icon="TwitchIcon"
              size="21"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '产品批次：'+data.item.batch_no+
              '<br>'+'开始：'+toDate(data.item.production_date)+
              '<br>'+'结束：'+toDate(data.item.expiry_date)+
              '<br>'+'负责人：'+data.item.our_contact+
              '<br>'+'负责人电话：'+data.item.our_mobile+
              '<br>'+'地址：'+data.item.our_location+
              '</div>'"
              variant="success"
          />
        </template>


        <template #cell(is_sel)="data">
          <b-form-checkbox
              name="check-button"
              v-model="data.item.is_sel"
              value="1"
              unchecked-value="0"
          >#{{ data.item.id }}
          </b-form-checkbox>
        </template>

        <!-- Column: Type -->
        <template #cell(salesoutbounditem_type)="data">
          {{ getCodeLabel('salesoutbounditem_type', data.item.salesoutbounditem_type) }}
        </template>

        <template #cell(receiveqty)="data">
          <span v-if="data.item.is_receive===0">对方尚未接收</span>
          <span
              v-if="data.item.is_receive===1">对方实收数量：{{ data.item.receiveqty }} / 退货(良品)：{{ data.item.returnqty_good }} / 退货(不良品)：{{ data.item.returnqty_bad }}</span>
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-$ {getCodeColor('state', data.item.state)}`"

            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-link @click="delivery(data.item)" v-if="data.item.status!==3">
            <feather-icon
                icon="ExternalLinkIcon"
                size="16"
                class="align-middle text-body"
            />
          </b-link>
          <span v-if="data.item.status===3">已出库</span>
        </template>

        <template #cell(receipt)="data">
          <b-link v-if="data.item.status!==0&&data.item.is_receive===0"
                  v-b-modal.modal-select-SalesOutboundItemWarehouseList @click="getId(data.item)">
            <feather-icon
                icon="FileTextIcon"
                size="16"
                class="align-middle text-body"
            />
          </b-link>
          <span v-if="data.item.status===0">无</span>
          <span v-if="data.item.status!==0&&data.item.is_receive!==0">已录入</span>
        </template>

        <template #cell(trueqty)="data">
          <b-form-input v-model="data.item.trueqty" style="width: 75px"
                        @change="changeTrueQty(data.item,$event);getqty(data.item)" v-if="data.item.status!==3">
          </b-form-input>
          <span v-if="data.item.status===3">{{ data.item.trueqty }}</span>
        </template>


      </b-table>

      <b-modal
          id="modal-select-SalesOutboundItemWarehouseList"
          no-close-on-backdrop
          no-close-on-esc
          ok-only
          ok-title="保存"
          @ok="saveReceipt()"
          centered
          size="lg"
          title="回执单"
      >
        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="2"
              label="计划出库数量"
              label-for="outbound_id"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="qty"
                size="sm"
                v-model="qty"
                readonly=""
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="2"
              label="实际出库数量"
              label-for="outbound_id"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="trueqty"
                size="sm"
                v-model="trueqty"
                readonly=""
            />
          </b-form-group>
        </b-col>

        <b-col md="12">
          <b-form-group
              label-cols="3"
              label-cols-lg="2"
              label="对方实收数量"
              label-for="outbound_id"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="receiveqty"
                size="sm"
                v-model="receiveqty"
                @change="changeNum('receiveqty',$event)"
            />
          </b-form-group>
        </b-col>

        <b-row>
          <b-col md="6">
            <b-form-group
                label-cols="3"
                label-cols-lg="4"
                label="对方退货数量(良品)"
                label-for="outbound_id"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="returnqtygood"
                  size="sm"
                  v-model="returnqtygood"
                  @change="changeNum('returnqtygood',$event)"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
                label-cols="3"
                label-cols-lg="2"
                label="库位"
                label-for="category_id"
                label-size="sm"
                class="mb-1"
            >
              <!--            v-model="categoryId"-->
              <!--            @input="changeCategoryId($event)"-->
              <v-select
                  :options="warehouselocationArray"
                  :clearable="true"
                  class="select-size-sm"
                  @input="changeLocationId('good',$event)"
                  label="zh_label"
              >
                <template #option="{ label }">
                  <b-badge
                      pill
                      :variant="`light-${getCodeColor('warehouse_location',label)}`"
                  >
                    {{ getCodeLabel('warehouse_location', label) }}
                  </b-badge>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col md="6">
            <b-form-group
                label-cols="3"
                label-cols-lg="4"
                label="对方退货数量(不良品)"
                label-for="outbound_id"
                label-size="sm"
                class="mb-1"
            >
              <b-form-input
                  id="returnqtybad"
                  size="sm"
                  v-model="returnqtybad"
                  @change="changeNum('returnqtybad',$event)"
              />
            </b-form-group>
          </b-col>

          <b-col md="6">
            <b-form-group
                label-cols="3"
                label-cols-lg="2"
                label="库位"
                label-for="category_id"
                label-size="sm"
                class="mb-1"
            >
              <v-select
                  :options="warehouselocationArray"
                  :clearable="true"
                  class="select-size-sm"
                  @input="changeLocationId('bad',$event)"
                  label="zh_label"
              >
                <template #option="{ label }">
                  <b-badge
                      pill
                      :variant="`light-${getCodeColor('warehouse_location',label)}`"
                  >
                    {{ getCodeLabel('warehouse_location', label) }}
                  </b-badge>
                </template>
              </v-select>
            </b-form-group>
          </b-col>
        </b-row>

      </b-modal>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup, BFormCheckbox, BAlert, BIcon, BootstrapVueIcons,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  second
} from '@core/utils/filter'
import salesoutbounditemUseList from './salesoutbounditemwarehouseUseList'
import salesoutbounditemStore from './salesoutbounditemStore'
import warehouseStore from "@/views/apps/warehouse/warehouseStore";
import storeStore from "@/views/apps/store/storeStore";
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import {useToast} from "vue-toastification/composition";
import warehouselocationStore from "@/views/apps/warehouselocation/warehouselocationStore";
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormGroup,
    flatPickr,
    BFormCheckbox,
    BAlert,
    BIcon,
    BootstrapVueIcons,
    VBTooltip,
    useToast,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('salesoutbounditem/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      warehouseArray: [],
      warehouse: {},
      warehouseId: 0,
      storeArray: [],
      store: {},
      storeId: 0,
      saveSalesOutboundArray: [],
      itemid: 0,
      qty: 0,
      trueqty: 0,
      receiveqty: 0,
      returnqtygood: 0,
      returnqtygoodlocationid: 0,
      returnqtybad: 0,
      returnqtybadlocationid: 0,
      ourwarehouseid: 0,
      flag: true,
      warehouselocationArray: [],
    }
  },
  props: ["salesoutbounditemid"],
  setup(props) {
    console.log("ids", props)
    const toast = useToast()
    // alert(props.outbound_id)
    // Register module
    if (!store.hasModule('salesoutbounditem')) store.registerModule('salesoutbounditem', salesoutbounditemStore)
    if (!store.hasModule('warehouse')) store.registerModule("warehouse", warehouseStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)
    if (!store.hasModule('warehouselocation')) store.registerModule('warehouselocation', warehouselocationStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesoutbounditem')) store.unregisterModule('salesoutbounditem')
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')
      if (store.hasModule('store')) store.unregisterModule('store')
      if (store.hasModule('warehouselocation')) store.unregisterModule('warehouselocation')
    })

    const changeIsGit = function (res) {
      if (res === 0) {
        return "否"
      } else {
        return "是"
      }
    }

    const edit = function () {

    }

    const saveSalesOutboundItem = function (result) {
      store
          .dispatch('salesoutbounditem/updateOrderItem', {
            itemid: result.outbounditem_id,
            qty: result.num,
            issel: result.is_sel,
            warehouseid: result.otherwarehouse_id,
            storeid: result.store_id,
            deliverytime: second(result.delivery_time),
            arrivaltime: second(result.arrival_time),
          })
          .then(response => {
            refetchData()
            toast.success("保存成功!")
          })
          .catch((e) => {
            toast.error("保存失败!")
          })

    }

    const changeDeliveryTime = function (val, event) {
      val.delivery_time = event
    }

    const changeArrivalTime = function (val, event) {
      val.arrival_time = event
    }

    const isGift = function (params) {
      if (params === 1) {
        return "(赠)"
      } else {
        return null
      }
    }

    const toParent = function () {
      this.$emit("table", this.saveSalesOutboundArray)
    }

    const delivery = function (params) {
      if (confirm("此操作只能执行一次，是否出库!")){
        store
            .dispatch('salesoutbounditem/outbound', {
              itemid: params.outbounditem_id,
              trueqty: params.trueqty,
            })
            .then(response => {
              if(response.data.code == 0) {
                refetchData()
                this.$emit("table")
                toast.success("出库成功!")
              }else{
                toast.error(response.data.data)
              }
            })
            .catch((e) => {
              refetchData()
              toast.error("出库失败!")
            })
      }

    }

    const changeTrueQty = function (params, event) {
      const cRegExp = /^[1-9]\d*$/
      if (!cRegExp.test(event)) {
        toast.error('数量必须是正整数!')
        params.trueqty = 0
      } else {
        params.trueqty = event
      }

    }

    const getqty = function (params) {
      store
          .dispatch('salesoutbounditem/getqty', {
            itemid: params.outbounditem_id,
          })
          .then(response => {
            let data = response.data.data
            if (params.trueqty > data) {
              toast.error("超出最大数量!")
              params.trueqty = data
            }
          })

    }

    const getId = function (params) {
      this.qty = params.qty
      this.trueqty = params.trueqty
      this.itemid = params.outbounditem_id
      this.ourwarehouseid = params.ourwarehouse_id
      this.returnqtygood = 0
      this.returnqtygoodlocationid = 0
      this.returnqtybad = 0
      this.returnqtybadlocationid = 0
      this.receiveqty=0
      this.warehouselocationArray.length=0
      this.getWarehouseLocationById(params.ourwarehouse_id)
    }

    const receiveitem = function (params) {
      store
          .dispatch('salesoutbounditem/receiveitem', {
            itemid: this.itemid,
            receiveqty: this.receiveqty,
            returnqtygood: this.returnqtygood,
            returnqtygoodlocationid: this.returnqtygoodlocationid,
            returnqtybad: this.returnqtybad,
            returnqtybadlocationid: this.returnqtybadlocationid,
          })
          .then(response => {
            let data = response.data.data
            if (params.trueqty > data) {
              toast.error("超出最大数量!")
              params.trueqty = data
            }
          })
    }

    const getWarehouseLocationById = function (params) {
      store
          .dispatch('warehouselocation/search', {
            warehouse_id: params
          })
          .then(response => {
            const list = response.data.data.list
            console.log("list", list)
            for (let i = 0; i < list.length; i++) {
              this.warehouselocationArray.push({"label": list[i].location_fullname, "locationid": list[i].id,zh_label:getCodeLabel('warehouse_location', list[i].location_fullname)})
            }
          })
    }

    const changeLocationId = function (condition, params) {
      switch (condition) {
        case 'good': {
          this.returnqtygoodlocationid = params.locationid
          console.log("good", this.returnqtygoodlocationid)
          break
        }
        case 'bad' : {
          this.returnqtybadlocationid = params.locationid
          console.log("bad", this.returnqtybadlocationid)
          break
        }
      }
    }

    const changeNum = function (condition, params) {
      const cRegExp = /^[1-9]\d*$/
      switch (condition) {
        case 'receiveqty' : {
          if (!cRegExp.test(params)) {
            toast.error('必须是正整数!')
            this.receiveqty = 0;
          }else {
            this.receiveqty = parseInt(params)
          }
          break
        }
        case 'returnqtygood' : {
          if (!cRegExp.test(params)) {
            toast.error('必须是正整数!')
            this.returnqtygood = 0;
          }else {
            this.returnqtygood = parseInt(params)
          }
          break
        }
        case 'returnqtybad' : {
          if (!cRegExp.test(params)) {
            toast.error('必须是正整数!')
            this.returnqtybad = 0;
          } else {
            this.returnqtybad = parseInt(params)
          }
          break
        }
      }
    }

    const saveReceipt = function () {
      if (confirm('该操作只能执行一次，确定操作吗？')) {
        if (this.returnqtygoodlocationid === 0&&this.returnqtygood!==0) {
          toast.error("请选择对应的仓位!")
          return false
        }
        if (this.returnqtybadlocationid === 0&&this.returnqtybad!==0) {
          toast.error("请选择对应的仓位!")
          return false
        }
        if (this.receiveqty + this.returnqtygood + this.returnqtybad !== this.trueqty) {
          toast.error("对方退货数量(不良品) + 对方退货数量(良品) + 对方实收数量 不等于 实际出库数量，请重新填写")
          return false
        }

        store
            .dispatch('salesoutbounditem/receiveitem', {
              itemid: this.itemid,
              receiveqty: this.receiveqty,
              returnqtygood: this.returnqtygood,
              returnqtygoodlocationid: this.returnqtygoodlocationid,
              returnqtybad: this.returnqtybad,
              returnqtybadlocationid: this.returnqtybadlocationid,
            })
            .then(response => {
              if (response.data.code===1){
                toast.error(response.data.data)
              }else {
                toast.success("保存成功!")
                refetchData()
              }

            })
      }
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = salesoutbounditemUseList(props.salesoutbounditemid)

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime, toDate,
      second,
      // UI
      changeIsGit,
      edit,
      saveSalesOutboundItem,
      changeDeliveryTime,
      changeArrivalTime,
      isGift,
      toParent,
      delivery,
      changeTrueQty,
      getqty,
      receiveitem,
      getId,
      getWarehouseLocationById,
      changeLocationId,
      changeNum,
      saveReceipt,
    }
  },
  created() {
    this.edit()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
